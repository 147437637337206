import { Injectable } from '@angular/core';
import { AuthApi } from '../apis/index.js';
import { SnackbarService } from '@awread/global/packages';
import { PlausibleService } from '@awread/kernel/services';
import { CurrentUserStore } from '../states/current-user/index.js';
import { AuthRoutingGear } from './auth-routing.gear.js';
import { CurrentUserGear } from './current-user.gear.js';
import { nanoid } from 'nanoid';
import { mergeMap } from 'rxjs/operators';
import { TransferTokenAddon } from '../addons/transfer-token.addon.js';
import { CreateUserCredential } from '../models/index.js';
import { Observable, of } from 'rxjs';
import { domainEnvironment } from '@awread/global/environments';
import { AuthorFacade } from '@awread/core/authors';
import { ForgotPasswordGear } from './forgot-password.gear.js';

@Injectable({ providedIn: 'root' })
export class RegisterGear {
  constructor(
    private currentUserStore: CurrentUserStore,
    private transferTokenAddon: TransferTokenAddon,
    private authApi: AuthApi,
    private snackbarService: SnackbarService,
    private currentUserGear: CurrentUserGear,
    private authRoutingGear: AuthRoutingGear,
    private plausibleService: PlausibleService,
    private forgotPasswordGear: ForgotPasswordGear,
    private authorFacade: AuthorFacade
  ) { }

  nanoid(): Observable<string> {
    const id = nanoid(9);
    return this.authApi.checkNid(id).pipe(
      mergeMap((count: number) => {
        if (count > 0) {
          return this.nanoid();
        } else {
          return of(id);
        }
      })
    );
  }

  createNewAccountFromSocial(user: any) {
    console.log('createNewAccountFromSocial', user);
    if (!user) return of();
    return this.nanoid().pipe(
      mergeMap((userNid) =>
        this.createNewAccount(
          {
            name: user.name,
            // TODO: username
            username: user.providerId,
            email: user.email,
            phone: user.phone,
            password: '',
            userNid: userNid,
          },
          { [user.provider]: user.providerId },
          {},
          user.provider
        )
      )
    );
  }

  fixStupidMail(email: string | undefined): string | undefined {
    if (email) {
      if (email.endsWith('@gmai.com')) {
        return email.replace('@gmai.com', '@gmail.com');
      } else if (email.endsWith('@gmai')) {
        return email.replace('@gmai', '@gmail.com');
      } else if (email.endsWith('@gmaicom')) {
        return email.replace('@gmaicom', '@gmail.com');
      } else if (email.endsWith('@gmailcom')) {
        return email.replace('@gmailcom', '@gmail.com');
      } else if (email.endsWith('@gmail')) {
        return email.replace('@gmail', '@gmail.com');
      } else {
        return email;
      }
    } else {
      return undefined;
    }
  }

  createNewAccount(requiredForm: CreateUserCredential, optionalForm: any, experienceForm: any, provider: string) {
    requiredForm.email = this.fixStupidMail(requiredForm.email);
    const socialConnect = {
      facebook: optionalForm.facebook,
      google: optionalForm.google,
      zalo: optionalForm.zalo,
      apple: optionalForm.apple,
      friendNid: this.getInviteQuest(),
    };
    return this.authApi
      .registerUser({
        ...requiredForm,
        // email: requiredForm.email?.toLowerCase(),
        email: this.fixStupidMail(requiredForm.email?.toLowerCase()),
        // email: undefined,
        provider,
        socialConnect,
      })
      .pipe(
        mergeMap((result: any) => {
          if (result.case == 'success') {
            if (this.getInviteQuest()) {
              this.plausibleService.trackEvent('invite-register');
            }
            if (requiredForm.email) {
              this.plausibleService.trackEvent('Signup-email', { props: { type: domainEnvironment.domain, provider } });
            } else {
              this.plausibleService.trackEvent('Signup', { props: { type: domainEnvironment.domain, provider } });
            }
            const userId = result.user.userId;
            console.log('register result', result, userId);
            return this.registerSuccess(result, provider);
          } else {
            return this.registerFail(result);
          }
        })
      );
  }

  private getInviteQuest() {
    const friendNid = localStorage.getItem('friendNid')?.length ? localStorage.getItem('friendNid') : undefined;
    if (friendNid) {
      localStorage.removeItem('friendNid');
    }
    return friendNid;
  }

  createPersonal(optionalForm: any, experienceForm: any) {
    return this.currentUserGear.createPersonal({
      ...optionalForm,
      ...experienceForm,
    });
  }

  registerSuccess(result: { accessToken: string; user: any }, provider: string) {
    // console.log('result', result);
    this.snackbarService.showSuccess(`Tạo tài khoản thành công!`);
    // if (provider === 'email') {
    //   this.snackbarService.showSuccess(`Đang gửi email xác minh để kích hoạt`);
    //   this.snackbarService.showSuccess(`Bạn có thể đăng nhập vào Awread luôn rồi nhé!`);
    //   this.activeMailGear.send(result.user.email).subscribe((res) => {
    //     this.snackbarService.showWarning(`Nhớ kích hoạt tài khoản qua mail nha`);
    //   });
    // }
    if (provider === 'email') {
      this.snackbarService.showSuccess(`Đang gửi email xác minh`);
      this.forgotPasswordGear.sendActiveEmail(result.user.email).subscribe((res) => {
        this.snackbarService.showWarning(`Vui lòng kiểm tra hòm thư email của bạn để kích hoạt tài khoản`);
      });
    }
    this.authRoutingGear.navigateHome();
    return of(result);
    // this.currentUserStore.update({ userId: result.userId });
    // this.transferTokenAddon.transfer(result?.accessToken);
  }

  registerFail(result: { case: string; duplicateField: { [s: string]: unknown } | ArrayLike<unknown> }) {
    console.log('register fail', result);
    if (result.case == 'duplicated') {
      const duplicator = Object.entries(result.duplicateField).filter(([key, value]) => {
        return !!value;
      });
      this.snackbarService.showError(`${duplicator[0][0]} đã được đăng ký`);
    } else {
      this.snackbarService.showError(`Đăng ký thất bại`);
    }
    return of({ accessToken: '', user: {} });
  }
}
