import { ApolloLink, split } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { environment } from '@awread/global/environments';
import { HttpLink, HttpLinkHandler } from 'apollo-angular/http';
import { OperationDefinitionNode } from 'graphql';
import { createPersistedQueryLink } from 'apollo-angular/persisted-queries';
import hashes from '../../../../../persisted-query-ids/client.json';
import { usePregeneratedHashes } from './use-pregenerated-hashes.tool.js';

import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

if (environment.production === false) {  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.warn(`[GraphQL error] Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (message.includes('role "reader" does not exist')) {
        localStorage.clear();
      }
      console.warn(`[GraphQL error] Error QueryName: ${operation.operationName}`, operation.query);
      console.warn(`[GraphQL error] Error QueryVariable: ${operation.operationName}`, operation.variables);
    });
  }


  if (networkError) {
    try {
      console.error('[Network error]', {
        response, operation,
        query: operation.query,
        variables: JSON.stringify(operation.variables, null, 2),
        name: networkError.name,
        message: networkError.message,
        cause: networkError.cause,
        stack: networkError.stack,
      });

    } catch (error) {
      console.error('[Network error]', {
        response, operation,
        query: operation.query,
        variables: operation.variables,
        name: networkError.name,
        message: networkError.message,
        cause: networkError.cause,
        stack: networkError.stack,
      });
    }
  }
});

// auth

export const authLink = new ApolloLink((operation, forward) => {
  let token = {};
  try {
    token = localStorage.getItem('accessToken')?.length ? { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } : {};
  } catch (error) {
  }
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...token,
      ...(environment.production === false ? { Mode: 'ad71148c79f21ab9eec51ea5c7dd2b668792f7c0d3534ae66b22f71c61523fb3' } : {}),
      // Referer: `${location.origin}/auth`,
    },
  }));
  return forward(operation);
});

// socket link
export let currentSocket: WebSocket;
let restartRequested = false;
export let restartSubscriptionsClient = () => {
  restartRequested = true;
};

import { createClient } from 'graphql-ws';

export function createSocketLink(http: HttpLinkHandler) {
  const wsUrl = `${location.protocol.includes('https') ? 'wss' : 'ws'}://${location.host}/v1/graphql`;
  // console.log('wsUrl', wsUrl);
  // NOTE: old version
  // const wsLink = new WebSocketLink({
  const wsLink = new GraphQLWsLink(
    createClient({
      url: wsUrl,

      lazy: true,
      connectionParams: () => {
        let token = {};
        try {
          token = localStorage.getItem('accessToken')?.length ? { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } : {};
        } catch (error) {
        }
        return {
          ...token,
          ...(environment.production === false ? { Mode: 'ad71148c79f21ab9eec51ea5c7dd2b668792f7c0d3534ae66b22f71c61523fb3' } : {}),
        };
      },
      on: {
        connecting: () => {
          // console.log('connecting socket...');
        },
        connected: (socket: any) => {
          currentSocket = socket;
          // console.log('socket connected!', socket);

          // restart by closing the socket which will trigger a silent reconnect
          restartSubscriptionsClient = () => {
            if (socket.readyState === WebSocket.OPEN) {
              socket.close(4205, 'Client Restart');
              console.warn('socket restarting...');
            }
          };

          // if any restarts were missed during the connection
          // phase, restart and reset the request
          if (restartRequested) {
            restartRequested = false;
            restartSubscriptionsClient();
          }
        },
        closed: (closeEvent: any) => {
          // console.log('new Error(`Socket closed with ${closeEvent.code} ${closeEvent.reason}`)');
          console.warn(`Socket closed with ${closeEvent.code} ${closeEvent.reason}`, closeEvent);
          // setState({
          //   socketStatus: 'closed',
          //   error: new Error(`Socket closed with ${closeEvent.code} ${closeEvent.reason}`),
          // });
        },
      },
    })
  );

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    (op) => {
      // if (op.operationName === 'listenToNotifications') {
      //   console.log('op?', op, op.getContext());
      // }
      const { kind, operation }: OperationDefinitionNode = getMainDefinition(op.query) as OperationDefinitionNode;
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    http
  );
  return link;
}

// persist link
export function createPersitedLink(hashesOnline?: any) {
  const persistedLink = createPersistedQueryLink({
    useGETForHashedQueries: false,
    generateHash: usePregeneratedHashes(hashes),
    disable: () => false,
  });
  return persistedLink;
}

// empty link
export const emptyLink = new ApolloLink((operation, forward) => {
  return forward(operation);
});
